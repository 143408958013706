import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import SubpageHeader from "../components/SubpageHeader";
import "styles/pages/page-other-solutions.scss";
import TitleWithLine from "../components/TitleWithLine";
import SectionHeading from "components/SectionHeading";
import Banner from "../components/Banner";
import SectionBanner from "../components/SectionBanner";
import Accordion from "../components/Accordion";
import TestimonialsSlider from "components/TestimonialsSlider/TestimonialsSlider";
import Seo from "components/Seo";
import NewContactForm from "components/NewContactForm";

const Solutions = ({ pageContext, data }) => {
  const sol = pageContext.pageContent;
  const testimonials = data.testimonials.edges;

  const accordionItems = sol.limitlessAnalysingAccordion.map((item, index) => {
    return {
      key: index,
      title: item.title,
      desc: item.text,
    };
  });

  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title="Solutions"
        image={require("assets/images/solutions-bg.jpg").default}
      />
      <section className="section-solutions">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-solutions__inner">
                <TitleWithLine variantY="bottom">
                  {sol.customSolutionsTitle}
                </TitleWithLine>
                <p
                  className="section-solutions__text"
                  dangerouslySetInnerHTML={{ __html: sol.customSolutionsText }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={sol.customSolutionsImage?.sourceUrl}
                alt=""
                className="img-fluid section-systems__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-work">
        <div className="container">
          <SectionHeading title={sol.howDoesItWorkTitle} />
          <div className="row">
            {sol.columns.map((item, index) => {
              return (
                <div className="col-xl-3 col-lg-6" key={index}>
                  <div className="section-work__title">
                    <TitleWithLine variantY="top">{item.title}</TitleWithLine>
                  </div>
                  <div className="single-tile">
                    <div className="single-tile__title">{item.subtitle}</div>
                    <div className="single-tile__inner single-tile__inner--1">
                      <p
                        className="single-tile__text"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Banner title={sol.divider1Title} />

      <section className="section-business">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={sol.youNeedImage?.sourceUrl}
                alt=""
                className="img-fluid section-business__img"
              />
            </div>
            <div className="col-lg-5">
              <div className="section-business__inner">
                <TitleWithLine variantY="bottom">
                  {sol.youNeedTitle}
                </TitleWithLine>
                <p
                  className="section-business__text"
                  dangerouslySetInnerHTML={{ __html: sol.youNeedText1 }}
                />
                <p className="section-business__text section-business__text--red">
                  {sol.youNeedTextDivider}
                </p>
                <p
                  className="section-business__text"
                  dangerouslySetInnerHTML={{ __html: sol.youNeedText2 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-limit">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-limit__inner">
                <TitleWithLine variantY="bottom">
                  {sol.limitlessAnalysingTitle}
                </TitleWithLine>
                <p className="section-limit__text">
                  {sol.limitlessAnalysingText}
                </p>
              </div>
              <Accordion
                className="accordin"
                visibleOnStart={true}
                items={accordionItems}
              />
            </div>
            <div className="col-lg-5">
              <img
                src={sol.limitlessAnalysingImage?.sourceUrl}
                alt=""
                className="img-fluid section-limit__img"
              />
            </div>
          </div>
        </div>
      </section>
      <SectionBanner
        title={sol.benefitsTitle}
        benefitPos={sol.benefitsLeft}
        benefitNeg={sol.benefitsRight}
        button="See More"
        className="banner-benefits"
        link="/benefits"
      />
      <section className="section-testimonials">
        <div className="container">
          <SectionHeading title={sol.testimonialsTitle} />
          <TestimonialsSlider testimonials={testimonials} />
        </div>
      </section>
      <NewContactForm />
    </Layout>
  );
};

export const query = graphql`
  query {
    testimonials: allWpTestimonial {
      edges {
        node {
          testimonialContent {
            avatar {
              sourceUrl
            }
            liUrl
            logo {
              sourceUrl
            }
            name
            role
            text
          }
        }
      }
    }
  }
`;

export default Solutions;
