import "./testimonials-slider.scss";

import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import theme from "theme";

const TestimonialsSliderWrapper = styled.div`
  margin-top: -60px;

  .slick-track {
    padding: 60px 0;
  }

  .slick-dots {
    bottom: -50px;
  }
`;

const SingleSlide = styled.div`
  background-color: #fff;
  border-bottom: 3px solid transparent;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px 5px 0 0;
  padding: 40px 30px 20px;
  position: relative;

  &:before {
    content: "„";
    position: absolute;
    left: 20px;
    top: -140px;
    font-size: 153px;
    opacity: 0.1;
  }
  ${theme.shadows.shadow}

  ${theme.mixins.onEvent} {
    border-bottom: 3px solid #ca3b6c;
  }
  ${theme.screens.mdScreen} {
    margin: 0;
  }
`;

const Text = styled.div`
  text-align: center;
  font-display: 16px;
  margin-bottom: 15px;
  min-height: 230px;
  ${theme.screens.mdScreen} {
    min-height: unset;
  }
`;

const AuthorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Avatar = styled.img`
  border-radius: 50px;
  width: 97px;
  height: 97px;
  object-fit: cover;
`;

const AvatarContainer = styled.div`
  position: relative;
  margin-right: 30px;
`;

const LinkedIn = styled.a`
  position: absolute;
  right: 5px;
  bottom: 10px;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 25px;
`;

const AuthorName = styled.div`
  font-size: 14px;
`;

const AuthorJob = styled.div`
  font-size: 12px;
  margin: -3px 0 10px;
`;

const TestimonialsSlider = ({ testimonials }) => {
  const testimonialsSlider = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    draggable: true,
    rtl: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <TestimonialsSliderWrapper>
      <Slider {...testimonialsSlider}>
        {testimonials.map((edges, index) => {
          const item = edges.node.testimonialContent;
          return (
            <div key={index}>
              <SingleSlide>
                <Text>{item.text}</Text>
                <AuthorContainer>
                  <AvatarContainer>
                    <Avatar src={item.avatar?.sourceUrl} alt="" />
                    <LinkedIn
                      href={item.liUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require("assets/icons/linkedin.svg").default}
                        alt=""
                      />
                    </LinkedIn>
                  </AvatarContainer>
                  <div>
                    <AuthorName>{item.name}</AuthorName>
                    <AuthorJob>{item.role}</AuthorJob>
                    <Logo src={item.logo?.sourceUrl} alt="" />
                  </div>
                </AuthorContainer>
              </SingleSlide>
            </div>
          );
        })}
      </Slider>
    </TestimonialsSliderWrapper>
  );
};

export default TestimonialsSlider;
